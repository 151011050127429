import axios, {AxiosError, Canceler} from 'axios';

import { Image, ImageOrigin, ImageType, ProjectTag, SearchResult, SystemTag } from './caseApiTypes';
import { UploadProgressHandlerForFile } from './commonApiTypes';
import { FilterableImage } from './filterTypes';
import { Clause, OrderBy } from './searchApiTypes';
import { uploadFiles } from './uploadApi';

let cancel: Canceler | undefined = undefined;

export async function search(
  query: string,
  filter: Clause<FilterableImage> | undefined,
  orderBy: OrderBy[],
  continuation?: number,
  pageSize: number = 100,
  searchOnlyFilename: boolean = false,
): Promise<SearchResult<Image>> {
  cancel?.();

  const cancelToken = new axios.CancelToken(c => (cancel = c));

  const searchFilter = {
    searchOnlyFilename,
    continuation,
    pageSize,
    filter,
    query,
    orderBy: orderBy.map(sb => `${sb.id} ${sb.desc ? 'desc' : 'asc'}`).join(','),
  };

  const { data } = await axios.post<SearchResult<Image>>('/api/image/search', searchFilter, { cancelToken });

  cancel = undefined;

  return data;
}

export async function findProductImagesForArticleIds(articleIds: string[]): Promise<Image[]> {
  cancel?.();

  articleIds = articleIds.filter(a => a && a.length > 0);
  if (articleIds.length === 0) {
    return [];
  }

  const cancelToken = new axios.CancelToken(c => (cancel = c));

  // since the default max length for an URL is 2000 characters, we may need to split the request into multiple requests.
  // With the calculations based on the length of the baseUrl and the 9 characters it takes for each articlesId,
  // the maximum number of ids is roughly 190
  const take = 190;
  let skip = 0;
  const images: Image[] = [];

  const promises = [];
  while (skip < articleIds.length) {
    const batch = articleIds.slice(skip, skip + take);
    promises.push(axios.get<Image[]>(`/api/image/search/productImagesByArticleIds?articleIds=${batch.join(',')}`, { cancelToken }));
    skip += take;
  }
  const results = await Promise.all(promises);

  for (const result of results) {
    images.push(...result.data);
  }

  cancel = undefined;

  return images;
}

export async function exportAsPdf(
  query: string,
  filter: Clause<FilterableImage> | undefined,
  orderBy: OrderBy[],
  continuation?: number,
  pageSize: number = 100,
  searchOnlyFilename: boolean = false,
) {
  cancel?.();

  const cancelToken = new axios.CancelToken(c => (cancel = c));

  const searchFilter = {
    searchOnlyFilename,
    continuation,
    pageSize,
    filter,
    query,
    orderBy: orderBy.map(sb => `${sb.id} ${sb.desc ? 'desc' : 'asc'}`).join(','),
  };
  return await axios.post<void>('/api/export/mediaGroup', searchFilter);
}

export async function getBatchByIds(ids: string[]): Promise<Image[]> {
  const query = ids.map(id => `id=${id}`).join('&');

  const url = `/api/image?${query}`;

  const { data } = await axios.get<Image[]>(url);

  return data;
}

export async function getById(id: string): Promise<Image> {
  const images = await getBatchByIds([id]);

  return images[0];
}

export async function updateImage(id: string, item: Partial<Image>): Promise<Image> {
  const url = `/api/image/${id}`;

  const { data } = await axios.put<Image>(url, item);

  return data;
}

export async function uploadVersion(id: string, origin: ImageOrigin, file: File, onUploadProgress?: UploadProgressHandlerForFile) {
  await uploadFiles(
    [file],
    'uploadImageVersion',
    {
      imageId: id,
      origin: origin.originSystem,
      originId: origin.originIdentifier,
    },
    onUploadProgress,
  );
  return await getById(id);
}

export async function setImageActiveVersion(id: string, activeVersion: string): Promise<Image> {
  const url = `/api/image/${id}/active-version`;

  const { data } = await axios.put<Image>(url, { activeVersion });

  return data;
}

export async function updateImageType(id: string, type: ImageType): Promise<Image> {
  const url = `/api/image/${id}/type`;

  const { data } = await axios.put<Image>(url, { type });

  return data;
}

export async function setProjectTag(image: Image, projectTag: ProjectTag): Promise<Image> {
  const url = `/api/image/${image.id}/tags/project`;

  const { data } = await axios.put<Image>(url, { tagId: projectTag.id });

  return data;
}

export async function clearProjectTag(image: Image): Promise<Image> {
  const url = `/api/image/${image.id}/tags/project`;

  const { data } = await axios.delete<Image>(url);

  return data;
}

export async function addSystemTags(image: Image, systemTags: SystemTag[]): Promise<Image> {
  const url = `/api/image/${image.id}/tags/system`;

  const { data } = await axios.post<Image>(url, { systemTagIds: systemTags.map(tag => tag.id) });

  return data;
}

export async function removeSystemTag(image: Image, systemTag: SystemTag): Promise<Image> {
  const url = `/api/image/${image.id}/tags/system`;

  const { data } = await axios.delete<Image>(url, { data: { tagId: systemTag.id } });

  return data;
}

export async function addProjectAndSystemTags(image: Image, projectTag: ProjectTag | undefined, systemTags: SystemTag[]): Promise<Image> {
  const url = `/api/image/${image.id}/tags`;

  const { data } = await axios.post<Image>(url, {
    projectTagId: projectTag?.id,
    systemTagIds: systemTags.map(tag => tag.id),
  });

  return data;
}
